<script type="text/javascript"></script>

<template>

	<div>
			
		<TopBar :columnnumber = "2" hidedropdown simple>
			<template v-slot:title>
				Portfolios
			</template>
			
		</TopBar>


        <membersModal :subject="portfolio" :isactive="portfolio?true:false" :myrole="myactiverole" :members="portfoliomembers" :checkforinvalidlink="false" :membersmodaltype="'portfolio'" :membersmodaleid="portfolio._id" :shown="membersadminshow" @onmodalhidden="membersModalHidden" :loggedinuser="loggedinuser" :token="token" @onmembersupdated="membersupdated"></membersModal>



        <b-modal @hide="showprojectmodalshown" @shown="showprojectmodalhidden" v-model="showprojectmodal" id ="approvalchanges2" size="md" no-close-on-backdrop no-close-on-esc>
            <template v-slot:modal-header>
              <h3 class="modal-title"> {{ activeboard.name }}</h3><br>
              <a class = "close-modal dialog-close-bustaketton mr-2">
                <i class="fal fa-times fa-lg " @click="showprojectmodal = false"></i>
              </a>
            </template>

            <portfolioProjectmodal :board="activeboard" :portfoliodata="portfolio" :parametersdata="portfolioforms" :portfolioid="pid" @onchangephase="changephase"></portfolioProjectmodal>

            <template v-slot:modal-footer="{ cancel, ok }">
              <button-gruppe text1= "Save" @no ="cancel" @yes="savevalues()"></button-gruppe>
            </template>
        </b-modal>


			<main class="main-wrapper cockpitview" style= "margin-top:21px;">

            <div class="cockpits">

            <b-modal size="sm" centered  hide-footer v-model="addProjectmodal" id="modal-addProjectmodal" title="add a project" @shown="openpickaproject">

                <template v-slot:modal-header>
              <h3 class="modal-title">Add a project</h3>
              <a class = "close-modal dialog-close-bustaketton mr-2">
                <i class="fal fa-times fa-lg " @click="addProjectmodal = false"></i>
              </a>
            </template>

                <vueselect id="pickproject" class="statuschooser mb-2" :searchable="true" :clearable = "false" :options="projects" :autoscroll="false" @input="projectPicked" v-model="selectedproject" @open="activegroup=0">
        
                </vueselect> 
              </b-modal>

            <div class="pb-2 d-flex align-items-center">            
                <button class="btn btn-primary mr-2" @click="gotoSettings">Portfolio settings</button>

                <button class="btn btn-secondary mr-2" @click="addProjectmodal=true">Add project</button>

                <button class="btn btn-secondary mr-2" @click="membersadminshow=true">Members</button>

            </div>

            <div v-if="1==2">

                <div class="headers d-flex align-items-start" style="border: solid 1px red; overflow: hidden;">

                    <div class="mr-1 phase phase-column" v-for="(group,key) in portfolio.groups" :key="'header_group_'+key">
                        <div class="card p-2 mb-3 phase-header">
                            {{ group.name }}
                        </div>

                    </div>
                    
                </div>
                <div class="contents">
                    
                </div>
                

            </div>

            <div class="d-flex align-items-start" id="phases"> 

                <template>
                <div style="height: 100vh" class="mr-1 phase phase-column" v-for="(group,key) in portfolio.groups" :key="'group_'+key">
                    <div class="card-header p-2 mb-3 phase-header">
                        {{ group.name }}
                    </div>

                    <div :id="group._id" class="p-2 cards-column" style="">

                        <portfolioProject  v-for="( board, key2 ) in group.boards" :key="'portfolioProject_'+'_'+key2+'_'+portfolioupdates[board]" :groupid="group._id" :group="group" :portfolioid="pid" @onprojectclicked="projectclicked" @onremoveproject="removeproject" :portfoliodata="portfolio" :parametersdata="portfolioforms" :projectdata="board" ></portfolioProject >

                        </div>

                                   
                </div>
                </template>
            </div>


            </div>
            </main>
	</div>
</template> 

<style type="text/css">
    .ghostClass{
        opacity: 0;
    }
    .bg-drop{
        background-color: var(--N200) !important;
    }

    .project{
        cursor: pointer;
    }

    .fw-bold{
        font-weight: bold;
    }

    .card-handle{
        cursor: move;
    }

    .cards-column{
        background-color: var(--N100);
        min-height: 200px;
    }

    .phase-column{
        min-width: 220px !important;
    }

    .card.project{
        width: 205px !important;
        max-width: 205px !important;
        min-width: 205px !important;
    }



</style>

<script>

	import {TopBar} from '@/components/topbar'

	import Portfolioapi from '@/services/api/portfolio'
	import vueselect from '@/views/tools/vueselect'	
	
    import portfolioProject from '@/views/portfolio/portfolioProject'
    import portfolioProjectmodal from '@/views/portfolio/portfolioProjectmodal.vue'
    import membersModal from '@/views/cockpit/components/membersModal.vue'


	import { v4 as uuidv4 } from 'uuid'

    import Sortable from 'sortablejs';

    //addClass

	export default{
		name:'portfoliodetail2',
		props:{
		},

		data(){
			//let SERVICE_URI =
				      
			    	return {
                        lanes:["one","two","three"],
                        portfolio:{},
                        portfolioforms:{},
                        name:"",
                        error:"",
                        selectedproject:{'label':'Select project'},
                        boards : [],
                        projects : [],
                        pid:"",
                        activegroup:0,
                        portfolioProjectkey:[],
                        excludes:[],
                        phases:[],
                        addProjectmodal:false,
                        showprojectmodal:false,
                        lastcolumn:null,
                        activeportfolio:{},
                        activeboard:"",
				        changes:0,
                        updatedid:0,
                        portfolioupdates:[],
                        pendingmovegroup:{},
                        membersadminshow:false,
                        updatememberslist:0,
                        canwrite:true,
                        myactiverole: 'Owner',
                        portfoliomembers: [],
                        membersmodalshown : false,


				    };
		},	

		 mounted() {	

            let self = this

            this.pid =  this.$route.params.portfolioid ? this.$route.params.portfolioid : ""

            setTimeout( function(){
                self.getportfolio(self.pid)
                self.getboards()
            },100)	 	

		 },

		watch:{
			
		},
		methods:{

            membersModalHidden(){
                this.membersadminshow = false
            },
            boardupdated(){},
            membersupdated(){},

            membersadminhide(){},
            membersadminshown(){},

            openpickaproject(){

                 this.getboards()

            },

            getprojects(){

                let toreturn = []

                if( this.boards ){

                    for(var i in this.boards){

                        let project = this.boards[i]
                        //console.log( this.excludes.indexOf( project._id ) , "xxx")
                        if( this.excludes.indexOf( project._id ) == -1 ){
                            project.label = project.name
                            toreturn.push(project)
                            //this.excludes.push( project._id )
                        }

                        
                    }
                }
                this.projects =  toreturn
            },

            startsearching(){

               //this.getboards()
            },

            changephase( projectid, fromgroup, togroup ){

                this.pendingmovegroup = { "from":fromgroup, "to":togroup, "projectid":projectid }

                //console.log( togroup, fromgroup , projectid, "fromgroup, togroup, projectid")
                //this.moveToGroup( fromgroup, togroup, projectid )

            },

            savevalues(){

                this.showprojectmodal = false

                let self = this

                if( this.pendingmovegroup.from && this.pendingmovegroup.to ){
                    this.moveToGroup( this.pendingmovegroup.from, this.pendingmovegroup.to, this.pendingmovegroup.projectid )

                }

                let projectid = this.activeboard._id

                let div = document.getElementById('projectformdetails')

                let form = document.createElement("form");

                form.appendChild(div);

                let formserialized = new URLSearchParams(new FormData(form))//.toString()


                let saveform = []
                formserialized.forEach( function( value, parameterid ){
                    saveform.push( {"value":value, "parameterid":parameterid} )
                })

                Portfolioapi.saveFormValue( { "saveform":JSON.stringify( saveform ), projectid:projectid,  portfolioid:this.pid, token:this.token, context:this.orgkey } ).then( function( result ){

                    if( result.data.ok ){

                        self.showprojectmodal = false

                        //self.updatedid++

                       // self.portfolioupdates[projectid] += 1

                        //console.log(  , "zzz")


                        /*result.data.updates.forEach( function( update ){
                            console.log( update.projectid )
                            //item = result.data.updates.find((item) => item.projectid === result.data.formvalue.value);
                        })*/

                        
                        
                        /*

                        setTimeout( function(){
                            self.getportfolio(self.pid)
                        },100)*/

                        
                        //self.portfolio = {}
                        self.getportfolio(self.pid)
                        
                    }
                })

                /**/

            },

            showprojectmodalshown(){},
            showprojectmodalhidden(){},

            projectclicked( project ){                

                //console.log( project )
               // return false

                let self = this

                this.activeboard = project

                setTimeout(function(){
                    self.showprojectmodal = true     
                },10)

            },

            bindsortables(){
                let self = this

                let phases = document.getElementsByClassName("cards-column")

                for(let i=0;i<phases.length;i++){

                    new Sortable( phases[i], {
                        group: 'shared', // set both lists to same group
                        animation: 150,
                        chosenClass:"shadow",
                        ghostClass:"ghostClass",
                        handle: '.card-handle',
                        onStart:function( ev ){
                           // alert('')
                        },
                        onEnd:function( ev ){

                            //console.log( ev.oldIndex, ev.newIndex, "evvv")

                            //self.moveToGroup( ev.oldIndex, ev.newIndex, ev.item.id )

                            $('.bg-drop').removeClass('bg-drop')

                        },
                        onAdd:function( ev ){

                            let fromcol = ev.from.id
                            let tocol = ev.to.id

                            self.moveToGroupSilent( fromcol, tocol, ev.item.id )                          

                            $('.bg-drop').removeClass('bg-drop')


                        },
                        onMove:function( ev ){

                            $('.bg-drop').removeClass('bg-drop')

                            ev.to.classList.add("bg-drop");

                            /**/
                            
                           /**/

                            //self.lastcolumn = ev.to
                           // 

                            /*console.log( self.lastcolumn.id == ev.to.id)

                            if( self.lastcolumn.id != ev.to.id ){
                                self.lastcolumn.classList.remove("bg-info")
                            }*/
                            

                            //console.log( ev.to, ev.from)
                            
                        }
                    });
                }

               /* 
*/

            },

            addnewgroup(){

                let self = this

                let input = ""

                if( input = prompt('Phase name') ){

                    if( input != '' ){

                        Portfolioapi.addNewGroup( { uuid:uuidv4(), name:input, portfolioid:this.pid, token:this.token, context:this.orgkey } ).then( function( result ){

                            if( result.data.ok ){
                                self.getportfolio(self.pid)
                                //self.getboards()
                            }
                        })
                    }

                    

                }

                

            },

            removeproject( projectid, groupid ){

                let self = this
               // this.project._id, this.groupid 

                Portfolioapi.removeProject( { gid:groupid, projectid:projectid, portfolioid:this.pid, token:this.token, context:this.orgkey } ).then( function( result ){

                    if( result.data.ok ){
                        self.getportfolio(self.pid)
                        
                    }
                })
            },

            moveToGroup( fromgroup, togroup, projectid ){

                let self = this

                Portfolioapi.removeProject( { gid:fromgroup, projectid:projectid, portfolioid:this.pid, token:this.token, context:this.orgkey } ).then( function( result ){

                    setTimeout( function(){

                        Portfolioapi.addProject( { gid:togroup, projectid:projectid, portfolioid:self.pid, token:self.token, context:self.orgkey } ).then( function( result ){
                            
                            self.getportfolio(self.pid)
                            self.pendingmovegroup = {}
                        
                        })


                    }, 100)

                    

                })
            },

            moveToGroupSilent( fromgroup, togroup, projectid ){

                let self = this

                Portfolioapi.removeProject( { gid:fromgroup, projectid:projectid, portfolioid:this.pid, token:this.token, context:this.orgkey } ).then( function( result ){

                    if( result.data.ok ){

                        Portfolioapi.addProject( { gid:togroup, projectid:projectid, portfolioid:self.pid, token:self.token, context:self.orgkey } )
                    }

                })


            },

            removeFromGroup( groupid, projectid ){

                let self = this

                Portfolioapi.removeProject( { gid:groupid, projectid:projectid, portfolioid:this.pid, token:this.token, context:this.orgkey } ).then( function( result ){

                    if( result.data.ok ){

                        /*var index = self.excludes.indexOf(projectid);
                        if (index !== -1) {
                          self.excludes.splice(index, 1);
                        }

                        let newboards = []
                        self.portfolio.groups[groupid].boards.forEach( function( boardid ){

                            console.log( projectid, boardid )

                            if( projectid != boardid ){
                                newboards.push(boardid)
                            }
                        });

                        self.portfolio.groups[groupid].boards = newboards

                        self.portfolioProjectkey[groupid]++*/
                        
                    }

                    

                })
            },


            addProject(){

                this.addProjectmodal = true
            },

            projectPicked( project){

                let self = this

                if( project && project._id ){

                    this.addProjectToPortfoliogroup( project._id, this.pid, this.activegroup )

                }
            },

            addProjectToPortfoliogroup( projectid, pid, activegroup ){

                this.addProjectmodal = false

                let self = this


                this.selectedproject = {'label':'Select project'}

                this.excludes.push(projectid)


                Portfolioapi.addProject( { groupid:activegroup, projectid:projectid, portfolioid:pid, token:this.token, context:this.orgkey } ).then( function( result ){

                    if( result.data.ok && result.data.portfolio ){

                        //self.portfolio.groups[0].push({})                        

                        self.portfolio.groups.forEach( function( group ){

                            if( group.default ){
                                console.log( result.data.project, "project" )
                                group.boards.push(result.data.project)
                            }
                            
                        })


                        //self.portfolio = {}
                        self.getportfolio(self.pid)
                    }

                })
            },

            getboards(){

                let self = this

                let excludes = this.excludes

                Portfolioapi.getboards( { excludes:excludes, token:this.token, orgkey:this.orgkey } ).then( function( result ){

                    if( result.data.ok && result.data.boards ){
                        self.boards = result.data.boards    

                        /*self.boards.forEach( function( board ){
                            self.projects.push( { "id":board._id, "label":board.name }  )
                        });    */            
                    }

                    setTimeout( function(){
                        self.getprojects()                        
                     }, 50)                   

                })
            },

            getportfolio( pid ){

                let self = this

                Portfolioapi.getOne( { pid:pid, token:this.token, orgkey:this.orgkey, parameters:true }).then( function( result ){

                    if( result.data.ok && result.data.portfolio ){

                        self.portfolio = result.data.portfolio
                        self.portfolioforms = result.data.parameters

                        let excludes = []

                        if( self.portfolio.groups ){

                            for( var i=0;i<self.portfolio.groups.length;i++){

                                let group = self.portfolio.groups[i]                                

                                for( var j=0;j<group.boards.length;j++){

                                    if( group.boards ){
                                        excludes.push( group.boards[j]._id )// = group.boards
                                    }

                                    self.portfolioupdates[ group.boards[j] ] = 0
                                }                               

                                self.phases.push(group.name)
                                
                            }

                            setTimeout( function(){
                                self.bindsortables()
                            }, 10)
                            

                        }

                        self.excludes = excludes

                        let sharedtousers = []


                        let members = []


                       if( self.portfolio.members && self.portfolio.members.users ){
                          members = members.concat(self.portfolio.members.users);                            
                       }

                       if( self.portfolio.members && self.portfolio.members.teams ){
                          members = members.concat(self.portfolio.members.teams);
                       }

                       self.portfoliomembers = members

                        

                    }

                })
            },

            gotoSettings() {
              this.$router.push('settings')
            },

			dialogOpen: function (args) {
		       // args.cancel = true;
		    },

            onPhasemovestart( card ){

                console.log(card,"start")

            },
            onPhasemove( i,o,j ){
                console.log(i,o,j)


                /*Portfolioapi.removeProject( { groupid:groupid, projectid:projectid, portfolioid:this.pid, token:this.token, context:this.orgkey } ).then( function( result ){

                })*/

                /*
let self = this

                Portfolioapi.saveGroups( {"portfolioid":this.portfolioid, "groups":JSON.stringify(this.editopts), "token":this.token} ).then( function( result ){

                    self.addingtype = ""
                    //self.getportfolio(self.pid)
                    //self.getboards()
                    //self.getportfolio(self.pid)
                    //self.opensettings = false
                })
                */
            },
		},

		computed:{

			/*projects(){

				let toreturn = []

				if( this.boards ){

					for(var i in this.boards){

						let project = this.boards[i]

						//console.log( this.excludes.indexOf( project._id ) , "xxx")

						if( this.excludes.indexOf( project._id ) == -1 ){
							project.label = project.name
							toreturn.push(project)
						}

						
					}
				}

				return toreturn
			},*/

            loggedinuser(){
                return this.$store.getters.user
            },

			cockpitid(){
        		return this.$route.params.cid
     		},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		},
		components:{ vueselect, membersModal, portfolioProject, portfolioProjectmodal, TopBar }
	}
	</script>