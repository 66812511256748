<script type="text/javascript"></script>

<template>  
	<div>
				<div v-show="portfolioforms" class="text-left" id="projectformdetails" onsubmit="return false">

				<div v-if="portfolioforms.length == 0">

					<b-skeleton width="40%"></b-skeleton>
					<b-skeleton width="80%"></b-skeleton>
					<b-skeleton width="30%"></b-skeleton>
					<b-skeleton width="60%"></b-skeleton>
					
				</div>
				<div v-else v-for="( row, key ) in portfolioforms" :key="key">

				<template v-if="row.active">

					<template v-if="row.type=='input'">

						<pText v-if="row.size=='small'" :item="row" :projectid="projectid"></pText>
						<pTextarea v-if="row.size=='big'" :item="row" :projectid="projectid"></pTextarea>					
					</template>


					<template v-if="row.type=='selection'">

						<pSelect v-if="row.size=='selection'" :item="row" :projectid="projectid"></pSelect>

						<pCheck v-if="row.size=='check'" :item="row" :projectid="projectid"></pCheck>


						<pOption v-if="row.size=='option'" :item="row" :projectid="projectid"></pOption>


					</template>

					<template v-if="row.type=='phase'">

						<div class="row mt-2 mr-2 ml-2">
						<label for="inputbig" class="col-3 col-form-label" v-b-tooltip.hover title="System parameter">Project phase</label>
						<div class="col-9 pl-0">
						<b-dropdown :text="group.name" class="" >
							<template v-for="(phase,phaseid) in phases">
						  		<b-dropdown-item v-if="phase._id!=group._id" :key="phaseid" @click.prevent.stop="changephase(projectid, phase)">{{ phase.name }}</b-dropdown-item>
						  		<b-dropdown-item v-else disabled :key="phaseid" @click.prevent.stop="changephase(projectid, phase)">{{ phase.name }}</b-dropdown-item>
						  	</template>
						</b-dropdown>
						</div>
						</div>					
						
					</template>

				</template>
				

				</div>
			</div>
		
	</div>
</template> 

<style type="text/css">
	

</style>

<script>

	import Portfolioapi from '@/services/api/portfolio'
	import Cockpitsapi from '@/services/api/cockpits'

	import pText from '@/views/portfolio/forms/pText.vue'
	import pTextarea from '@/views/portfolio/forms/pTextarea.vue'
	import pSelect from '@/views/portfolio/forms/pSelect.vue'
	import pCheck from '@/views/portfolio/forms/pCheck.vue'
	import pOption from '@/views/portfolio/forms/pOption.vue'


	export default{
		name:'portfolioProjectmodal',
		props:{
			portfolioid:String,
			portfoliodata:Object,
			parametersdata:Array,
			board:Object,
			projectdata:Object
		},

		data(){
			return{
				project:{},
				portfolio:{},
				portfolios:[],
				projectid:"",
				name:"",
				error:"",				
				portfolioforms:[],
				group:{"_id":null,name:"Select"},
			}
		},

		 mounted() {

		 	let self = this

		 	this.project = this.board
		 	this.projectid = this.project._id
		 	this.getportfolio()
            
		 },

		watch:{
			data : function(){
				this.portfolio = this.data
			}
		},
		methods:{			

			getportfolio(){

				let self = this
				//, values:true
				/*Portfolioapi.getOne( { pid:this.portfolioid, token:this.token, orgkey:this.orgkey, parameters:true }).then( function( result ){

	                if( result.data.ok && result.data.portfolio ){
	                    self.portfolio = result.data.portfolio
	                    self.portfolioforms = result.data.parameters

	                    self.getgroup()
	                }

            	})*/

            	this.portfolio = this.portfoliodata
	            this.portfolioforms = this.parametersdata
	            this.getgroup()

			},

			changephase( projectid, phase ){
				
				this.$emit('onchangephase', projectid, this.group._id, phase._id )
				this.group = phase
			},

			getgroup(){

				let self = this

				let phase = {}

				if( this.portfolio && this.portfolio.groups ){

					let cntr = 0
					this.portfolio.groups.forEach(function( group ){
						if( group.boards ){
							let boards = group.boards
							group.boards.forEach(function( board ){
								if( self.projectid == board._id ){
									phase = group
								}
							})
						}		

						cntr++				
					})

				}

				this.group = phase


			},
		},

		computed:{

			phases(){

				let phases = []

				if( this.portfolio && this.portfolio.groups ){

					this.portfolio.groups.forEach(function( group ){
						phases.push(group)
					})

				}

				return phases
			},

			
			cockpitid(){
        		return this.$route.params.cid
     		},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		},

		components:{ pText, pTextarea, pSelect, pCheck, pOption }
	}
	</script>