<script type="text/javascript"></script>

<template>

	<div class="card mb-2 project" :id="projectid" >

		<div class="" >

			<div class="card-header card-handle p-0">
				<div class="d-flex align-items-center">

					<div class="m-1 flex-grow-1 projectname" style="word-break: break-word;">{{ project.name }}</div>

					<div class="m-1">
						<b-dropdown id="dropdown-1" text="..." class="" toggle-class="text-decoration-none"  variant="link" no-caret>

						<template #button-content><i class="far fa-ellipsis-v"></i></template>
				    	<b-dropdown-item @click.stop.prevent="tryremovefromgroup()">remove</b-dropdown-item>
				  		</b-dropdown>
			  		</div>

				</div>
    			
  			</div>

			<div class="accent" v-if="1==2"></div> 

			<div @click="projectclicked(project)" class="align-self-center w-100 p-2 text-right" style="position: relative;">

				<div v-if="!portfolioforms">
					<b-skeleton width="100%"></b-skeleton>
				</div>
				<div v-else :class="{ 'compact':!expand && 1==2 }">
					<div v-for="( row, key ) in portfolioforms" :key="key" class="text-left" >
						<template v-if="row.active">

							<div v-if="row.type!='phase'"><span class="fw-bold">{{ row.name }}</span>: {{ getvalue(row._id) }}
								</div>

						</template>
					</div>
				</div>

				<div v-if="portfolioforms.length > 2 && 1==2" class="pt-2 mt-2">
					<a v-if="!expand" href="#" @click.stop.prevent="expand=true">
						<i  class="fas fa-caret-circle-down"></i>
					</a>
					<a v-else href="#" @click.stop.prevent="expand=false">
						<i  class="fas fa-caret-circle-up"></i>
					</a>
				</div>	

			</div>

		</div>

	</div>
</template> 

<style type="text/css">

	.projectname{

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

	}
	
	.compact{
		max-height: 65px;
		overflow: hidden;
	}

	.card2{
		background: #fff;
	}

</style>

<script>

	import Portfolioapi from '@/services/api/portfolio'
	import Cockpitsapi from '@/services/api/cockpits'


	import {bus} from '@/main'

	export default{
		name:'portfolioProject',
		props:{
			groupid:String,
			portfolioid:String,
			group:Object,
			portfoliodata:Object,
			parametersdata:Array,
			projectdata:Object		
		},

		data(){
			return{
				projectid:"",
				project:{},
				forms:{},
				formsaved:{},
				newforms:{},
				selectedoption:null,
				portfolio:{},
				portfolioforms:[],
				expand:false
			}
		},

		 mounted() {

		 	//if( this.projectid ){
		 		let self = this



		 		setTimeout( function(){
		 			self.loadportfolio()
		 		}, 50)
		 		
		 		


		 	//}

		 	
		 },

		watch:{

			/*'portfolio.forms' : function(e){

				alert('cc')
			}*/

			/*projectid : function( e ) {
				alert( e )
			}*/
		},
		methods:{

			getvalue( parameterid ){

				//this.projectdata.parametervalues []

				let toreturn = ""

				let param = this.portfolioforms.find((item) => item._id === parameterid);
				let values = this.projectdata.parametervalues.find((item) => item.parameterid === parameterid);


				if( values ){

					let tovalue = values.value

					let tovalues = []

					let x = tovalue.split(",")



					x.forEach( function( o ){



						if( o != '' ){


							if( param.options && param.options.length > 0 ){

								let option = param.options.find((item) => item._id === o);

								if( option ){
									tovalues.push(option.name)
								}else{
									if( values.parameter && values.parameter.options ){
										let option = values.parameter.options.find((item) => item._id === o);
										if( option ){
											tovalues.push(option.name)
										}
									}
								}
							}else{

								tovalues.push(o)

							}


						}

					})

					toreturn = tovalues.join(", ")

				}					

				/*this.portfolioforms.forEach( function( form ){
					if( parameterid == form._id){
					}
					console.log( parameterid, form._id  , "xx")
				})*/

				return toreturn

			},

			tryremovefromgroup(){
				this.$emit('onremoveproject', this.project._id, this.groupid )				
			},


			projectclicked( project ){
				this.$emit('onprojectclicked', project )
			},

			loadportfolio(){


				let self = this

				this.portfolio = this.portfoliodata
	            this.portfolioforms = this.parametersdata
	            this.project = this.projectdata
	            this.projectid = this.projectdata._id

	            setTimeout( function(){
				//			self.loadboard()
						}, 10)

				/*Portfolioapi.getOne( { pid:this.portfolioid, token:this.token, orgkey:this.orgkey, parameters:true }).then( function( result ){

	                if( result.data.ok && result.data.portfolio ){
	                    self.portfolio = result.data.portfolio
	                    self.portfolioforms = result.data.parameters

	                   // self.getgroup()

	                    
	                }

            	})*/

				/*Portfolioapi.getOne( { pid:this.portfolioid, token:this.token, orgkey:this.orgkey }).then( function( result ){

					if( result.data.ok && result.data.portfolio ){

						self.portfolio = result.data.portfolio

						setTimeout( function(){
							self.loadboard()
						}, 10)

					}
				})*/

			},

			loadboard(){



				let self = this

				Cockpitsapi.Cockpitspreload( {"_id": this.projectid, "token": this.token } ).then( function( result ){

		 			if( result.data.ok && result.data.cockpits ){


						self.project = result.data.cockpits


						////self.getformvalue()
					}

		 		})


			},

			getformvalue(){

				//console.log( "getformvalue" )

				if( this.portfolio ){

					let self = this


					Portfolioapi.getformvalue(  { "portfolioid":this.portfolioid, "projectid":this.projectid, "token": this.token } ).then( function( result ){

						let values = result.data.formvalue.values

						console.log( values, "values")

						if( values && values.length > 0 ){

							//self.forms = formvalue.form

							for( let i = 0; i < self.portfolio.forms.length; i++  ){

								let form = self.portfolio.forms[i]

								for( let o = 0; o < values.length; o++  ){

									if( form.uuid == values[o].formid ){
										self.portfolio.forms[i].value = values[o].value
										self.portfolio.forms[i].values = values[o].values
									}
									
								}
							}
							
						}

						

					})

				}

				
			},

			updatevalue( item, item2 ){


				for( let i = 0; i < this.portfolio.forms.length; i++  ){

					let form = this.portfolio.forms[i]
					//console.log( form.uuid + "-" + item.uuid)	
					if( form && form.uuid == item.uuid ){						

						let val = this.portfolio.forms[i]

						let newval = val//.concat({ "value":"", "type" : "", "text" : "", "selected" : "" })

						val = newval

						val.value = item.value

						if( item.type && ( item.type == 'selection' || item.type == 'checks' ) ){


							if( val.options ){

								//console.log( val.options, item ,"lkk")

								for( let k = 0; k < val.options.length ; k++  ){

									if(val.options[k].selected){
										delete(val.options[k].selected)
									}
									if(val.options[k].text){
										delete(val.options[k].text)
									}

								}

								for( let k = 0; k < val.options.length ; k++  ){
									

									if( Array.isArray(item.value) ){

										val.values = item.value
										val.value = null

										if( item.value.indexOf( val.options[k].uuid ) > -1 ){

											val.options[k].selected = true
											val.options[k].text = item.text

										}

									}else{

										if( val.options[k].uuid == item.value ){

											val.options[k].selected = true
											val.options[k].text = item.text

										}

									}


								}
							}

							

						}

						//console.log( val, "val")

						this.portfolio.forms[i] = val

						let formsaved = this.portfolio.forms

						/*this.$nextTick(function() {
							bus.$emit('onformupdated', { "projectid":this.projectid } )
						})*/
						

						this.saveformvalue( formsaved )

						//console.log( this.portfolio.forms, "update")
					}

				}
				
				console.log("updatevalue")

			},

			saveformvalue( formsaved ){

				let self = this;

				Portfolioapi.saveFormValue( { "portfolioid":this.portfolio._id, "projectid":this.projectid, "formvalue": JSON.stringify(formsaved) }  ).then( function( result ){

					console.log( result )

				})

			},


			changephase( projectid, groupid){

				this.$emit('onmoveToGroup', this.groupid, groupid, projectid)

			},

			addnewgroup(){

				this.$emit('onaddnewgroup')

			},

			removeFromGroup( projectid ){

				this.$emit('onremoveFromGroup', this.groupid, this.projectid)	


			}
		},

		computed:{

			/*portfolioforms(){

				let toreturn = []

				if( this.portfolio.forms ){					

				    toreturn = this.portfolio.forms

				}

				return toreturn

			},*/

			cockpitid(){
        		return this.$route.params.cid
     		},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		},
		components:{}
	}
	</script>