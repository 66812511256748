<script type="text/javascript"></script>

<template>  
	<div class="">	
	<div class="row mt-2 mr-2 ml-2">
	<label for="inputsmall" class="col-3 col-form-label text-left">{{ item.name }}</label>
	<input maxlength="250" type="text" class="form-control col-9" id="inputsmall" placeholder="" :name="item._id" :value="itemvalue">
	</div>
	</div>
</template> 

<style type="text/css">
	

</style>

<script>

	import Portfolioapi from '@/services/api/portfolio'

	export default{
		name:'pText',
		props:{
			viewmode:String,
			projectid:String,
			item:Object,
		},

		data(){
			return{
				portfolios:[],
				name:"",
				error:"",
				tmr:null,
				mode:'',
				itemvalue:''
			}
		},

		 mounted() {

			let self = this

			setTimeout( function(){
				self.gettext()
			},10)


		 },

		watch:{

			
		},
		methods:{

			gettext(){

				let self = this

				if( this.item && this.item._id ){

					Portfolioapi.getFormvalue( { parameterid:this.item._id, projectid:this.projectid, token:this.token, context:this.orgkey } ).then( function( result ){

		                    if( result.data.ok ){
		                    	self.itemvalue = result.data.formvalue.value
		                    }
	                })

				}				
				
			},

			textupdated( el ){
			}
		},

		computed:{

			cockpitid(){
        		return this.$route.params.cid
     		},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		},

		components:{}
	}
	</script>