<script type="text/javascript"></script>

<template>  
	<div class="">
		
		<div class="row mt-2 mr-2 ml-2">
			<input type="hidden" :name="item._id" :value="selectedoption._id" >
			<label for="inputbig" class="col-3 col-form-label">{{ item.name }}</label>
			<div class="col-9 pl-0">
			<b-dropdown :text="selectedoption.name" class="p-0 m-0">
			  <b-dropdown-item v-for="(option,key2) in item.options" :key="key2" @click="selected(option)">{{ option.name }}</b-dropdown-item>
			</b-dropdown>
			</div>
		</div>	
	</div>
</template> 

<style type="text/css">
	

</style>

<script>

	import Portfolioapi from '@/services/api/portfolio'
	import {bus} from '@/main'

	export default{
		name:'pSelect',
		props:{
			viewmode:String,
			projectid:String,
			item:Object,
		},

		data(){
			return{
				selectedoption:{_id:"",name:'Select'},
				portfolios:[],
				name:"",
				error:"",
				mode:'',
				formupdated:{},
				missingoption:{}
			}
		},

		mounted() {

			let self = this

				 setTimeout( function(){
			 	 	self.getselected()
			 	 },100)


		},

		watch:{
			
		},
		methods:{

			selected( option ){

				this.selectedoption = option
			},

			getselected(){

				let self = this

				if( this.item && this.item._id ){

					Portfolioapi.getFormvalue( { parameterid:this.item._id, projectid:this.projectid, token:this.token, context:this.orgkey } ).then( function( result ){

		                    if( result.data.ok && result.data.parameter ){
	
		                    		self.parameter = result.data.parameter
		                    		self.selectedvalue = result.data.formvalue.value
		                    		self.formupdated = result.data.formvalue

		                    		let item = null

		                    		if( result.data.parameter.options ){
		                    			item = result.data.parameter.options.find((item) => item._id === result.data.formvalue.value);
		                    		}		                    			

		                    		if( item ){

		                    			self.selectedoption = {"_id":result.data.formvalue.value, "name":item.name}
		                    		}else{

		                    			if( result.data.formvalue.parameter ){

		                    				self.parameter = result.data.formvalue.parameter

			                    			const item = self.parameter.options.find((item) => item._id === result.data.formvalue.value);

			                    			if( item && item.name ){
			                    				self.selectedoption = {"_id":result.data.formvalue._id, "name":item.name}
			                    			}

		                    			}		                    			
		                    			
		                    		}

		                    }
	                })

				}				
				

				
			},
		},

		computed:{

			/*updatedform( obj ){

				console.log( obj )
			},*/

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		},

		components:{}
	}
	</script>