<script type="text/javascript"></script>

<template>  
	<div class="">
	
	<div class="row mt-2 mr-2 ml-2">
	<label for="inputcheck" class="col-3 col-form-label">{{ item.name }}</label>
	<div class="col-9 pl-0"> 

		<b-form-checkbox disabled v-model="checked" v-for="(missing,key3) in missingselection" :key="'missing_'+key3">{{ missing }}</b-form-checkbox>

		<input type="hidden" :name="item._id" v-model="selection"> 
		<b-form-checkbox v-model="selection" :value="option._id" v-for="(option,key2) in item.options" :key="key2" @change="selectionupdated">{{ option.name }}</b-form-checkbox>
	</div>
	</div>
	</div>
</template> 

<style type="text/css">
	

</style>

<script>

	import Portfolioapi from '@/services/api/portfolio'
	import {bus} from '@/main'

	export default{
		name:'pCheck',
		props:{
			viewmode:String,
			projectid:String,
			item:Object,
		},

		data(){
			return{
				portfolios:[],
				name:"",
				error:"",
				mode:"",
				formupdated:{},
				selection:[],
				parameter:{},
				oldparameter:{},
				checked:true
			}
		},

		 mounted() {

		 	//bus.$off('onformupdated')
		 	 //bus.$on('onformupdated', this.updatedform);


		 	 //console.log( this.item.values , "xxxxxxxxxvvv")

		 	 let self = this

		 	 setTimeout( function(){
		 	 	self.getchecked()
		 	 },100)

		 	 


		 },

		watch:{			
		},
		methods:{

			selectionupdated(){

				this.checked = false
			},

			getText( optionvalues ){

				let self = this
				let toreturn = []				

				if( this.parameter && this.parameter.options ){

					this.missingselection.forEach( function( missing ){		
							//if( option._id == optionvalue ){						
								toreturn.push( missing )
							//}
						})

					optionvalues.forEach( function( optionvalue ){
						self.parameter.options.forEach( function( option ){		
							if( option._id == optionvalue ){						
								toreturn.push(option.name)
							}
						})
					})					
					
				}

				return toreturn.join(", ")
			},

			getchecked(){

				let self = this

				if( this.item && this.item._id ){

					Portfolioapi.getFormvalue( { parameterid:this.item._id, projectid:this.projectid, token:this.token, context:this.orgkey } ).then( function( result ){

		                    if( result.data.ok ){
		                    	let values = result.data.formvalue.values
		                    	self.parameter = result.data.parameter
		                    	self.oldparameter = result.data.formvalue.parameter
		                    	self.selection = values ? values : []
		                    }
	                })

				}

			},
			
		},

		computed:{			

			missingselection(){

				let self = this

				let toreturn = []

				this.selection.forEach( function( option ){
					//toreturn.push( option )

					const item = self.parameter.options.find((item) => item._id === option);

					if(item==null){
						const item2 = self.oldparameter.options.find((item) => item._id === option);
						if( item2 && item2.name ){
							toreturn.push( item2.name )
						}
						
					}
				})

				return toreturn
			},
			cockpitid(){
        		return this.$route.params.cid
     		},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		},

		components:{}
	}
	</script>