<script type="text/javascript"></script>

<template>
	<div class="">
	<div class="row mt-2 mr-2 ml-2">
	<label for="inputradio" class="col-3 col-form-label">{{ item.name }}</label>

	<div  class="col-9 pl-0">

	<b-form-radio-group :id="'radio_'+item._id" :name="item._id" class="pl-0" v-model="selectedvalue">


		<b-form-radio v-if="missingoption && missingoption._id" class="pl-0 text-red" :name="item._id" :value="formupdated.value" disabled>{{ getText(formupdated.value) }}</b-form-radio>
		<b-form-radio class="pl-0" :name="item._id" :value="option._id" v-for="(option,key2) in item.options" :key="'radioitem_'+key2">{{ option.name }}</b-form-radio>	
	</b-form-radio-group>
	</div>

	</div>

	</div>
</template> 

<style type="text/css">
	
	.text-red{
		text-decoration: underline;
	}

</style>

<script>

	import Portfolioapi from '@/services/api/portfolio'
	import {bus} from '@/main'


	export default{
		name:'pOption',
		props:{
			viewmode:String,
			projectid:String,
			item:Object,
			itemkey:String,
			withmissing:false
			//selected:String
		},

		data(){
			return{
				portfolios:[],
				name:"",
				error:"",
				mode:"",
				selectedvalue:null,
				formupdated:{},
				checked:null,
				parameter:{},
				missingoption:{},
				//selected:true
			}
		},

		 mounted() {

		 	

		 	 let self = this

		 	 setTimeout( function(){
		 	 	self.getchecked()
		 	 },100)

		 },

		watch:{

			
		},
		methods:{

			getText( optionvalue ){

				let self = this
				let toreturn = ""

				if( this.parameter && this.parameter.options ){

					this.parameter.options.forEach( function( option ){
						if( option._id == optionvalue ){
							toreturn = option.name
						}
					})
					
				}

				return toreturn
			},

			getchecked(){

				let self = this

				if( this.item && this.item._id ){

					Portfolioapi.getFormvalue( { parameterid:this.item._id, projectid:this.projectid, token:this.token, context:this.orgkey } ).then( function( result ){

		                    if( result.data.ok ){	                    	//optionnotfound


		                    	let item = {}

		                    	if( result.data.parameter.options ){
		                    		item = result.data.parameter.options.find((item) => item._id === result.data.formvalue.value);
		                    	}
		                    	
	                    	
		                    	if( !item ){
		                    		//alert('a')

		                    		item = result.data.formvalue.parameter.options.find((item) => item._id === result.data.formvalue.value);


		                    		self.parameter = result.data.formvalue.parameter
		                    		self.selectedvalue = result.data.formvalue.value
		                    		self.formupdated = result.data.formvalue
		                    		self.missingoption = item

		                    	}else{
		                    		//alert('b')
		                    		self.parameter = result.data.parameter
		                    		self.selectedvalue = result.data.formvalue.value
		                    		self.formupdated = result.data.formvalue	                    		
		                    	}

		                    	
		                    }
	                })

				}

			},

		},

		computed:{

			cockpitid(){
        		return this.$route.params.cid
     		},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		},

		components:{}
	}
	</script>